import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getNewsById, viewNewsById, readNewsById } from "../apis/news.api"; // Create a new API function for getting news by ID
import parse from "html-react-parser";
import "./NewsDetail.css";
import { useNavigate } from "react-router-dom";
import { ArrowBack, Check, IosShare } from "@mui/icons-material";
import useSwipe from "../hooks/useSwipe";
import { Chip, Stack, Button } from "@mui/material";

const NewsDetailWeb = () => {
  const { id, page } = useParams();
  const [newsDetail, setNewsDetail] = useState(null);
  const [pagesContent, setPagesContent] = useState(null);
  const [currentContentPage, setCurrentContentPage] = useState(0);
  const [maxContentPage, setMaxContentPage] = useState(0);
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;
  const navigate = useNavigate();

  useEffect(() => {
    // set page = 1 if page is not a number
    if (isNaN(page)) {
      navigate(`/news/${id}/1`);
    }

    const fetchNewsDetail = async () => {
      try {
        const detail = await getNewsById(id);
        document.title = detail.title;
        document.querySelector("meta[name='description']").setAttribute("content", detail.description);
        setNewsDetail(detail);
        appendChunksToPages(getNodeChunks(detail.content, detail), detail);

        // get the view news list from localStorage
        const viewNewsList = JSON.parse(localStorage.getItem("viewNews")) || [];
        // check if the id is already in the viewNews array
        if (!viewNewsList.includes(id)) {
          // call the viewNews API
          viewNewsById(id);

          // add the id to the viewNews array
          viewNewsList.push(id);

          // check if the viewNews array has more than 100 items
          if (viewNewsList.length > 100) {
            viewNewsList.shift();
          }

          // save the viewNews array to localStorage
          localStorage.setItem("viewNews", JSON.stringify(viewNewsList));
        }
      } catch (error) {
        console.error("Error fetching news detail:", error);
        // Handle error, e.g., show an error message to the user
      }
    };

    fetchNewsDetail();

    // wait 0.5s for the modal to open
    setTimeout(() => {
      // replace all iframes with data-src attribute with src attribute
      const iframes = document.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        // check if the iframe has data-src attribute
        if (!iframe.dataset.src) {
          return;
        }
        iframe.src = iframe.dataset.src;
      });
    }, 300);
  }, [id]);

  // hide all pages except the current page
  useEffect(() => {
    if (!pagesContent) {
      return;
    }
    const pages = document.querySelectorAll(".page");
    pages.forEach((page, index) => {
      if (index === currentContentPage) {
        page.style.display = "block";
      } else {
        page.style.display = "none";
      }
    });
  }, [currentContentPage, pagesContent]);

  const getNodeChunks = (htmlDocument, newsDetail) => {
    // if (newsDetail.featureImage && newsDetail.featureImage !== "") {
    //   htmlDocument = newsDetail.featureImage + htmlDocument;
    // }

    // add newsDetail.featureImage to the beginning of htmlDocument
    let contentHtml = htmlDocument;
    // add source to the beginning of htmlDocument
    contentHtml =
      `<p>Nguồn: <a href="${newsDetail.link}" target="_blank" rel="noreferrer">${newsDetail.source}</a></p>` +
      contentHtml;
    if (newsDetail.featureImage && newsDetail.featureImage !== "") {
      contentHtml = newsDetail.featureImage + contentHtml;
    }
    let offscreenDiv = document.createElement("div");
    offscreenDiv.className = "page-test fck-detail";
    offscreenDiv.style.position = "absolute";
    offscreenDiv.style.left = "-3000px";
    offscreenDiv.innerHTML = contentHtml;
    offscreenDiv.display = "flex";
    offscreenDiv.flexWrap = "wrap";
    if (windowWidth < 1000) {
      offscreenDiv.style.width = windowWidth - 50 + "px";
    } else {
      offscreenDiv.style.width = "980px";
    }
    // count number of words in newsDetail.title
    // if number of words in newsDetail.title is more than 25, set height of offscreenDiv to windowHeight - 150px
    // else set height of offscreenDiv to windowHeight - 100px
    const titleWords = newsDetail.title.split(" ");
    if (windowWidth < 768) {
      if (titleWords.length > 25) {
        offscreenDiv.style.height = windowHeight - 160 + "px";
      } else if (titleWords.length > 12) {
        offscreenDiv.style.height = windowHeight - 140 + "px";
      } else {
        offscreenDiv.style.height = windowHeight - 100 + "px";
      }
    } else if (windowWidth < 992) {
      offscreenDiv.style.height = windowHeight - 120 + "px";
    } else {
      offscreenDiv.style.height = windowHeight - 150 + "px";
    }

    // get all the img elements in offscreenDiv
    // set height of all img elements to 300px
    const imgs = offscreenDiv.querySelectorAll("img");
    imgs.forEach((img) => {
      if (windowWidth < 768) {
        img.style.height = "300px";
      } else if (windowWidth < 992) {
        img.style.height = "400px";
      } else if (windowHeight < 1200 && windowHeight < 650) {
        img.style.height = "400px";
      } else {
        img.style.height = "450px";
      }
      img.style.width = "auto";
    });

    document.body.appendChild(offscreenDiv);

    let offscreenRect = offscreenDiv.getBoundingClientRect();
    let chunks = [];
    let currentChunk = [];
    for (let i = 0; i < offscreenDiv.children.length; i++) {
      let current = offscreenDiv.children[i];
      let currentRect = current.getBoundingClientRect();
      currentChunk.push(current);
      if (currentRect.bottom > offscreenRect.bottom) {
        // current element is overflowing offscreenDiv, remove it from current chunk
        currentChunk.pop();
        // remove all elements in currentChunk from offscreenDiv
        currentChunk.forEach((elem) => elem.remove());
        // since children were removed from offscreenDiv, adjust i to start back at current eleme on next iteration
        i -= currentChunk.length;
        // push current completed chunk to the resulting chunklist
        chunks.push(currentChunk);
        // initialise new current chunk
        currentChunk = [current];
        offscreenRect = offscreenDiv.getBoundingClientRect();
      }
    }
    // currentChunk may not be empty but we need the last elements
    if (currentChunk.length > 0) {
      currentChunk.forEach((elem) => elem.remove());
      chunks.push(currentChunk);
    }
    // offscreenDiv is not needed anymore
    // offscreenDiv.remove();
    return chunks;
  };

  const appendChunksToPages = (chunks, newsDetail) => {
    let pagesContent = "";
    chunks.forEach((chunk, index) => {
      const page = document.createElement("div");
      page.className = "page page" + index;
      // add page header
      const pageHeader = document.createElement("div");
      pageHeader.className = "page-header";
      // add page title
      const pageTitle = document.createElement("h2");
      pageTitle.innerHTML = newsDetail.title;
      pageHeader.appendChild(pageTitle);
      // add page source
      // const pageSource = document.createElement("p");
      // pageSource.innerHTML = "Nguồn: ";
      // const pageSourceLink = document.createElement("a");
      // pageSourceLink.href = newsDetail.link;
      // pageSourceLink.target = "_blank";
      // pageSourceLink.rel = "noreferrer";
      // pageSourceLink.innerHTML = newsDetail.source;
      // pageSource.appendChild(pageSourceLink);
      // pageHeader.appendChild(pageSource);
      page.appendChild(pageHeader);

      // add page content
      chunk.forEach((elem) => page.appendChild(elem));

      // add page number
      const pageNumber = document.createElement("div");
      pageNumber.className = "page-number";
      pageNumber.innerHTML = "Trang " + (index + 1) + "/" + chunks.length;
      // add hint
      const hint = document.createElement("div");
      hint.className = "hint";
      hint.innerHTML =
        "Chạm lề trái/phải hoặc dùng phím mũi tên trái/phải, page up/down, volume up/down trên bàn phím để chuyển trang.";
      pageNumber.appendChild(hint);
      page.appendChild(pageNumber);

      // get all the img elements in page
      // remove height and width of all img elements
      const imgs = page.querySelectorAll("img");
      imgs.forEach((img) => {
        img.style.height = "";
        img.style.width = "";
      });
      pagesContent += page.outerHTML;
    });

    setPagesContent(pagesContent);
    setMaxContentPage(chunks.length - 1);
    setCurrentContentPage(0);
  };

  const swipeHandlers = useSwipe(
    {
      onTouchLeft: () => {
        handlePrevPage();
      },
      onTouchRight: () => {
        handleNextPage();
      },
    },
    { touchScreenPart: 3 }
  );

  // call handlePrevPage when pressing left arrow key, volume up key, or swipe left, or page up key
  useEffect(() => {
    const handlePrevPageByKeypress = (event) => {
      if (event.keyCode === 37 || event.keyCode === 38 || event.keyCode === 33 || event.keyCode === 183) {
        handlePrevPage();
      }
    };
    window.addEventListener("keydown", handlePrevPageByKeypress);
    return () => {
      window.removeEventListener("keydown", handlePrevPageByKeypress);
    };
  }, [currentContentPage, maxContentPage]);

  // call handleNextPage when pressing right arrow key, volume down key, or swipe right, or page down key
  // set page += 1 when pressing right arrow key, volume down key, or swipe right, or page down key
  useEffect(() => {
    const handleNextPageByKeypress = (event) => {
      if (event.keyCode === 39 || event.keyCode === 40 || event.keyCode === 34 || event.keyCode === 182) {
        handleNextPage();
      }
    };
    window.addEventListener("keydown", handleNextPageByKeypress);
    return () => {
      window.removeEventListener("keydown", handleNextPageByKeypress);
    };
  }, [currentContentPage, maxContentPage]);

  const handlePrevPage = () => {
    if (currentContentPage > 0) {
      setCurrentContentPage(currentContentPage - 1);

      // hide tags-list
      const tagsList = document.getElementById("tags-list");
      if (tagsList) {
        tagsList.style.display = "none";
      }

      // set padding-bottom of .fck-detail to 60px
      const fckDetail = document.getElementsByClassName("fck_detail")[0];
      console.log("fckDetail: ", fckDetail);
      if (fckDetail) {
        fckDetail.style.paddingBottom = "60px";
      }
    }
  };

  const handleNextPage = () => {
    if (currentContentPage < maxContentPage) {
      setCurrentContentPage(currentContentPage + 1);
    }

    if (currentContentPage + 1 === maxContentPage) {
      // display tags-list
      const tagsList = document.getElementById("tags-list");
      if (tagsList) {
        tagsList.style.display = "flex";
      }

      // set padding-bottom of .fck-detail to 0
      const fckDetail = document.getElementsByClassName("fck_detail")[0];
      if (fckDetail) {
        fckDetail.style.paddingBottom = "0";
      }
    }
  };

  if (!newsDetail) {
    return <p>Loading...</p>;
  }

  const handleReadNews = () => {
    // get the read news from localStorage
    const readNews = JSON.parse(localStorage.getItem("readNews")) || [];
    // check if the id is already in the readNews array
    if (!readNews.includes(id)) {
      // call the readNews API
      readNewsById(id);

      // add the id to the readNews array
      readNews.push(id);

      // check if the readNews array has more than 100 items
      if (readNews.length > 100) {
        readNews.shift();
      }
    }
    // save the readNews array to localStorage
    localStorage.setItem("readNews", JSON.stringify(readNews));

    navigate(`/${page}`);
  };

  const handleGoBack = () => {
    navigate(`/${page}`);
  };

  const handleShare = async () => {
    const url = window.location.href;

    try {
      await navigator.share({ title: newsDetail.title, text: newsDetail.description, url });
      console.log("Data was shared successfully");
    } catch (err) {
      console.error("Share failed:", err.message);
    }
  };

  const handleTagClick = (tag) => () => {
    console.log("tag: ", tag);
    // save the tag to localStorage
    localStorage.setItem("tag", JSON.stringify(tag));
    // mark item as read
    // go back to home page and search by tag
    handleReadNews();
  };

  return (
    <div className="news_item">
      <div className="fck_detail" {...swipeHandlers}>
        {/* <h2>{newsDetail.title}</h2>
        <p>
          Nguồn:&nbsp;
          <a href={newsDetail.link} target="_blank" rel="noreferrer">
            {newsDetail.source}
          </a>
        </p>
        <p>{newsDetail.description}</p> */}
        <div id="article-content">{pagesContent && parse(pagesContent)}</div>
      </div>
      {newsDetail.tags && newsDetail.tags.length > 0 && (
        <Stack direction="row" spacing={1} id="tags-list" style={{ display: "none" }}>
          {newsDetail.tags.map((tag) => (
            <Chip label={tag.name} color="success" onClick={handleTagClick(tag)} />
          ))}
        </Stack>
      )}
      <div className="actionBtns">
        <Stack spacing={2} direction="row" justifyContent="center">
          <Button variant="contained" color="error" onClick={handleReadNews}>
            <Check />
          </Button>
          <Button variant="contained" onClick={handleShare}>
            <IosShare />
          </Button>
          <Button variant="outlined" onClick={handleGoBack}>
            <ArrowBack />
          </Button>
        </Stack>
      </div>
    </div>
  );
};

export default NewsDetailWeb;
