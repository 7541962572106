import { pdfjs } from "react-pdf";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./PdfViewer.css";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const PdfViewer = () => {
  const { page } = useParams();
  const [numPages, setNumPages] = useState(1);
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(1.0);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    if (parseInt(page) > numPages) setPageNumber(numPages);
  }

  useEffect(() => {
    setPageNumber(parseInt(page) || 1);

    const resizeListener = () => {
      const height = window.innerHeight - 100;
      const scale = height / 550;
      setScale(scale);
      console.log(scale);
    };
    window.addEventListener("resize", resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);
  const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1);

  // call handlePrevPage when pressing left arrow key, volume up key, or swipe left, or page up key
  useEffect(() => {
    const handlePrevPageByKeypress = (event) => {
      if (event.keyCode === 37 || event.keyCode === 38 || event.keyCode === 33 || event.keyCode === 183) {
        goToPrevPage();
      }
    };
    window.addEventListener("keydown", handlePrevPageByKeypress);
    return () => {
      window.removeEventListener("keydown", handlePrevPageByKeypress);
    };
  }, [numPages, pageNumber]);

  // call handleNextPage when pressing right arrow key, volume down key, or swipe right, or page down key
  // set page += 1 when pressing right arrow key, volume down key, or swipe right, or page down key
  useEffect(() => {
    const handleNextPageByKeypress = (event) => {
      if (event.keyCode === 39 || event.keyCode === 40 || event.keyCode === 34 || event.keyCode === 182) {
        goToNextPage();
      }
    };
    window.addEventListener("keydown", handleNextPageByKeypress);
    return () => {
      window.removeEventListener("keydown", handleNextPageByKeypress);
    };
  }, [numPages, pageNumber]);

  return (
    <div className="pdf-viewer">
      <nav>
        <button onClick={goToPrevPage} className="previous">
          Prev
        </button>
        <button onClick={goToNextPage} className="next">
          Next
        </button>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </nav>

      <div className="document-wrapper">
        <Document file="/assets/CompetitionMathv34_pass.pdf" onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} scale={scale} />
        </Document>
      </div>
    </div>
  );
};

export default PdfViewer;
