import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getNewsById } from "../apis/news.api"; // Create a new API function for getting news by ID
import parse from "html-react-parser";
import "./NewsDetail.css";

const NewsDetail = () => {
  const { id } = useParams();
  const [newsDetail, setNewsDetail] = useState(null);

  useEffect(() => {
    const fetchNewsDetail = async () => {
      try {
        const detail = await getNewsById(id);
        setNewsDetail(detail);
      } catch (error) {
        console.error("Error fetching news detail:", error);
        // Handle error, e.g., show an error message to the user
      }
    };

    fetchNewsDetail();

    // wait 0.5s for the modal to open
    setTimeout(() => {
      // replace all iframes with data-src attribute with src attribute
      const iframes = document.querySelectorAll("iframe");
      iframes.forEach((iframe) => {
        // check if the iframe has data-src attribute
        if (!iframe.dataset.src) {
          return;
        }
        iframe.src = iframe.dataset.src;
      });
    }, 300);
  }, [id]);

  if (!newsDetail) {
    return <p>Loading...</p>;
  }

  return (
    <div className="fck_detail">
      <h2>{newsDetail.title}</h2>
      <p>
        Nguồn:&nbsp;
        <a href={newsDetail.link} target="_blank" rel="noreferrer">
          {newsDetail.source}
        </a>
      </p>
      <p>{newsDetail.description}</p>
      
    </div>
  );
};

export default NewsDetail;
