// src/components/NewsItem.js
import React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import TimelineOppositeContent from "@mui/lab/TimelineOppositeContent";
import { Whatshot } from "@mui/icons-material";
import { useEffect } from "react";
import Grid from "@mui/material/Grid";
import "./NewsTimelineList.css";

const NewsTimelineList = ({ newsData, readNews, onClick, rows, columns = 1 }) => {
  const [data, setData] = React.useState([]);
  useEffect(() => {
    // create a new array of arrays with the length of columns
    const newColumns = Array.from({ length: columns }, () => []);
    // loop through the newsData and add each newsItem to the newColumns array
    newsData.results.forEach((newsItem, index) => {
      newColumns[index % columns].push(newsItem);
    });
    // update the data state
    setData(newColumns);
  }, [columns, newsData.results, rows, readNews]);

  const renderTimelineDot = (newsItem) => {
    // check if newsItem._id is in readNews
    if (readNews.includes(newsItem._id)) {
      return <TimelineDot style={{ marginLeft: "2px" }} />;
    }

    // hot news
    if (newsItem.isHot) {
      return (
        <TimelineDot color="error" style={{ padding: "0px" }}>
          <Whatshot style={{ fontSize: "12px" }} />
        </TimelineDot>
      );
    }

    // normal
    return <TimelineDot color="primary" style={{ marginLeft: "2px" }} />;
  };

  const getNewsItemClass = (newsItem) => {
    // check if newsItem._id is in readNews
    if (readNews.includes(newsItem._id)) {
      return "read";
    }

    // hot news
    if (newsItem.isHot) {
      return "hot";
    }

    // normal
    return "normal";
  };

  return (
    <Grid container spacing={2}>
      {data.map((columnData, index) => (
        <Grid item xs={12} sm={12 / columns} md={12 / columns} lg={12 / columns} key={index}>
          <Timeline className="news-timeline-list">
            {columnData.map((newsItem, rowIndex) => (
              <TimelineItem
                key={newsItem._id}
                style={{ minHeight: "40px" }}
                className={getNewsItemClass(newsItem)}
                onClick={() => onClick(newsItem)}
              >
                <TimelineOppositeContent style={{ display: "none" }}></TimelineOppositeContent>
                <TimelineSeparator>
                  {renderTimelineDot(newsItem)}
                  {rowIndex < rows - 2 && <TimelineConnector style={newsItem.isHot ? {} : { marginLeft: "2px" }} />}
                </TimelineSeparator>
                <TimelineContent style={{ lineHeight: 1.1, paddingRight: "0px" }}>{newsItem.title}</TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Grid>
      ))}
    </Grid>
  );
};

export default NewsTimelineList;
