import React, { useState, useEffect } from "react";
import { Select, MenuItem, Button, FormControl, InputLabel, Grid, Container, CssBaseline } from "@mui/material";
import useSwipe from "../../hooks/useSwipe";

const MentalMath = () => {
  const [operation, setOperation] = useState(localStorage.getItem("operation") || "addition");
  const [displayOperation, setDisplayOperation] = useState("+");
  const [number1, setNumber1] = useState(0);
  const [number2, setNumber2] = useState(0);
  const [result, setResult] = useState("?");

  useEffect(() => {
    handleRefreshClick();
  }, [operation]);

  // call handleRefreshClick when pressing left arrow key, volume up key, or swipe left, or page up key
  useEffect(() => {
    const handlePrevPageByKeypress = (event) => {
      if (event.keyCode === 37 || event.keyCode === 38 || event.keyCode === 33 || event.keyCode === 183) {
        handleRefreshClick();
      }
    };
    window.addEventListener("keydown", handlePrevPageByKeypress);
    return () => {
      window.removeEventListener("keydown", handlePrevPageByKeypress);
    };
  }, [operation]);

  // call handleRefreshClick when pressing right arrow key, volume down key, or swipe right, or page down key
  useEffect(() => {
    const handleNextPageByKeypress = (event) => {
      if (event.keyCode === 39 || event.keyCode === 40 || event.keyCode === 34 || event.keyCode === 182) {
        handleRefreshClick();
      }
    };
    window.addEventListener("keydown", handleNextPageByKeypress);
    return () => {
      window.removeEventListener("keydown", handleNextPageByKeypress);
    };
  }, [operation]);

  const swipeHandlers = useSwipe(
    {
      onTouchLeft: () => {
        handleRefreshClick();
      },
      onTouchRight: () => {
        handleRefreshClick();
      },
    },
    { touchScreenPart: 3 }
  );

  async function generateRandomNumberForAddition() {
    // generate random from 2 - 9
    let num1 = Math.floor(Math.random() * 8) + 2;
    // prevent the 10 case
    if (num1 === 10) {
      num1 = 9;
    }

    // generate number 2
    // min = 11 - num1
    // max = 9
    // if num1 = 2, num2 = 9
    // if num1 = 3, num2 = 8, 9
    // ...
    // if num1 = 9, num2 = 2, 3, 4, 5, 6, 7, 8, 9
    let num2 = Math.floor(Math.random() * (num1 - 2)) + (11 - num1);
    if (num2 === 10) {
      num2 = 9;
    }

    // prevent the same number1 and number2
    if (num1 === number1 && num2 === number2) {
      return await generateRandomNumberForAddition();
    }
    // for ten's complement case
    if (num1 === number1 && num2 === "?") {
      return await generateRandomNumberForAddition();
    }
    return { num1, num2 };
  }

  async function generateRandomNumberForSubtraction() {
    // generate random from 11 - 18
    let num1 = Math.floor(Math.random() * 8) + 11;
    // prevent the 19 case
    if (num1 === 19) {
      num1 = 18;
    }

    // generate number 2
    // min = num1 - 9
    // max = 9
    // if num1 = 11, num2 = 2, 3, 4, 5, 6, 7, 8, 9
    // if num1 = 12, num2 = 3, 4, 5, 6, 7, 8, 9
    // if num1 = 13, num2 = 4, 5, 6, 7, 8, 9
    // ...
    // if num1 = 18, num2 = 9
    let num2 = Math.floor(Math.random() * (18 - num1)) + (num1 - 9);
    if (num2 === 10) {
      num2 = 9;
    }

    // prevent the same number1 and number2
    if (num1 === number1 && num2 === number2) {
      return await generateRandomNumberForSubtraction();
    }
    return { num1, num2 };
  }

  const handleOperationChange = (event) => {
    setOperation(event.target.value);

    // save the operation to local storage
    localStorage.setItem("operation", event.target.value);
  };

  const handleRefreshClick = async () => {
    if (operation === "addition") {
      const { num1, num2 } = await generateRandomNumberForAddition();
      setNumber1(num1);
      setNumber2(num2);
      setResult("?");
      setDisplayOperation("+");
    } else if (operation === "subtraction") {
      const { num1, num2 } = await generateRandomNumberForSubtraction();
      setNumber1(num1);
      setNumber2(num2);
      setResult("?");
      setDisplayOperation("-");
    } else if (operation === "mix") {
      const random = Math.random();
      if (random < 0.5) {
        const { num1, num2 } = await generateRandomNumberForAddition();
        setNumber1(num1);
        setNumber2(num2);
        setResult("?");
        setDisplayOperation("+");
      } else {
        const { num1, num2 } = await generateRandomNumberForSubtraction();
        setNumber1(num1);
        setNumber2(num2);
        setResult("?");
        setDisplayOperation("-");
      }
    } else if (operation === "ten_complement") {
      const { num1 } = await generateRandomNumberForAddition();
      setNumber1(num1);
      setNumber2("?");
      setResult(10);
      setDisplayOperation("+");
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={styles.container} {...swipeHandlers}>
      <CssBaseline />
      <Grid container spacing={2} alignItems="center" justify="center" direction="column">
        <Grid item>
          <FormControl variant="outlined">
            <InputLabel>Select Operation</InputLabel>
            <Select
              value={operation}
              onChange={handleOperationChange}
              label="Select Operation"
              style={{ minWidth: "200px" }}
            >
              <MenuItem value="addition">Addition</MenuItem>
              <MenuItem value="subtraction">Subtraction</MenuItem>
              <MenuItem value="ten_complement">Ten's Complement</MenuItem>
              <MenuItem value="mix">Mix (Addition or Subtraction)</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <div style={styles.operation}>
            {number1} {displayOperation} {number2} = {result}
          </div>
        </Grid>
      </Grid>
      <Button
        variant="contained"
        color="primary"
        onClick={handleRefreshClick}
        style={styles.refreshButton}
        size="large"
      >
        Refresh
      </Button>
    </Container>
  );
};

export default MentalMath;

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "90vh",
  },
  operation: {
    fontSize: "4em",
    fontWeight: "bold",
    marginTop: "20px",
    marginBottom: "20px",
  },
  refreshButton: {
    position: "fixed",
    bottom: "30px",
  },
};
