// src/api/newsApi.js
import { get, post } from "../services/api.service";

const getNews = async (params) => {
  return get("/news", params);
};

const postAllNewsExcluded = async (data) => {
  return post("/news/getAllExcluded", data);
};

const getNewsById = async (id) => {
  const params = { id };
  return get(`/news/detail`, params);
};

const viewNewsById = async (id) => {
  const params = { id };
  return get(`/news/view`, params);
};

const readNewsById = async (id) => {
  const params = { id };
  return get(`/news/read`, params);
};

export { getNews, postAllNewsExcluded, getNewsById, viewNewsById, readNewsById };
