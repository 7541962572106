// src/components/Home.js
import React, { useEffect, useState } from "react";
import { getNews } from "../apis/news.api";
import NewsTimelineList from "../components/NewsTimelineList";
import "./Home.css";
import Stack from "@mui/material/Stack";
import { useNavigate, useParams } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { SearchBar, SearchIconWrapper, StyledInputBase } from "../components/SearchBar";
import LeftDrawer from "../components/LeftDrawer";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import { FormControl } from "@mui/material";
import useSwipe from "../hooks/useSwipe";
import { useStore } from "../hooks/useStore";

import NavBarUserComponent from "../components/NavBarUserComponent";

const Home = () => {
  const [newsData, setNewsData] = useState(null);
  const [loading, setLoading] = useState(true);
  const { pageno } = useParams();
  const [page, setPage] = useState(pageno ? parseInt(pageno) : 1);
  const [totalPages, setTotalPages] = useState(1);
  const [numberOfNews, setNumberOfNews] = useState(10);
  const [selectedNews, setSelectedNews] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [columns, setColumns] = useState(1);
  const [readNews, setReadNews] = useState([]);
  const [source, setSource] = useState(localStorage.getItem("source") || "all");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTermTemp, setSearchTermTemp] = useState(
    localStorage.getItem("tag") ? JSON.parse(localStorage.getItem("tag")).name : ""
  );
  const navigate = useNavigate();
  const screenWidth = window.innerWidth;

  useEffect(() => {
    const tag = JSON.parse(localStorage.getItem("tag"));
    if (tag) {
      setSearchTerm(tag.name);

      // remove tag from localStorage
      localStorage.removeItem("tag");
    }
    fetchDataFromApis();
  }, [page, source, searchTermTemp]);

  // set page -= 1 when pressing left arrow key, volume up key, or swipe left, or page up key
  useEffect(() => {
    const handlePrevPageByKeypress = (event) => {
      if (page === 1) return;

      if (event.keyCode === 37 || event.keyCode === 38 || event.keyCode === 33 || event.keyCode === 183) {
        const prevPage = Math.max(parseInt(page) - 1, 1);
        setPage(prevPage);
        setLoading(true);
      }
    };
    window.addEventListener("keydown", handlePrevPageByKeypress);
    return () => {
      window.removeEventListener("keydown", handlePrevPageByKeypress);
    };
  }, [page]);

  // set page += 1 when pressing right arrow key, volume down key, or swipe right, or page down key
  useEffect(() => {
    const handleNextPageByKeypress = (event) => {
      if (page === totalPages) return;

      if (event.keyCode === 39 || event.keyCode === 40 || event.keyCode === 34 || event.keyCode === 182) {
        const nextPage = Math.min(parseInt(page) + 1, totalPages);
        setPage(nextPage);
        setLoading(true);
      }
    };
    window.addEventListener("keydown", handleNextPageByKeypress);
    return () => {
      window.removeEventListener("keydown", handleNextPageByKeypress);
    };
  }, [page, totalPages]);

  const swipeHandlers = useSwipe({ onTouchRight: () => handleNextPage(), onTouchLeft: () => handlePrevPage() });

  const fetchDataFromApis = async () => {
    try {
      // calucalte numberOfNews based on viewport width and height
      const width = window.innerWidth;
      const height = window.innerHeight;
      const columns = width < 768 ? 1 : width < 1350 ? 2 : 3;
      let itemHeight = 50;

      let numberOfNews = (height - 150) / itemHeight;
      numberOfNews = Math.floor(numberOfNews) * columns;
      setColumns(columns);
      if (columns === 1 && width < 768) {
        if (width < 450) {
          if (source === "cafef") {
            itemHeight = 75;
          } else if (source === "all") {
            itemHeight = 70;
          } else {
            itemHeight = 60;
          }
        } else {
          if (source === "cafef") {
            itemHeight = 65;
          } else if (source === "all") {
            itemHeight = 65;
          } else {
            itemHeight = 60;
          }
        }
        numberOfNews = height / itemHeight;
      }

      setNumberOfNews(numberOfNews);

      // get the read news from localStorage
      // const readNews = JSON.parse(localStorage.getItem("readNews")) || [];
      const newsData = await getNews({
        page,
        limit: numberOfNews,
        excluded: [],
        filters: { searchTerm, source },
      });

      // Combine or process the data as needed
      setNewsData(newsData);

      // Set loading to false since the data has been fetched
      setLoading(false);

      // calculate the total number of pages
      setTotalPages(Math.ceil(newsData.count / numberOfNews));

      // set the readNews state
      let readNews = JSON.parse(localStorage.getItem("readNews")) || [];
      setReadNews(readNews);
    } catch (error) {
      // Handle error, e.g., show an error message to the user
    }
  };

  const handlePrevPage = () => {
    if (page === 1) return;

    const prevPage = Math.max(parseInt(page) - 1, 1);
    setPage(prevPage);
    setLoading(true);
  };

  const handleNextPage = () => {
    if (page === totalPages) return;

    const nextPage = Math.min(parseInt(page) + 1, totalPages);
    setPage(nextPage);
    setLoading(true);
  };

  const handleCardClick = (newsItem) => {
    // setSelectedNews(newsItem);
    // setIsModalOpen(true);
    navigate(`/news/${newsItem._id}/${page}`);
  };

  const handleRefresh = () => {
    setPage(1);
    setLoading(true);
  };

  const handleSearchTermChange = (event) => {
    setSearchTerm(event.target.value);
    // wait for 500ms before calling handleRefresh
    // if the user types again within 500ms, cancel the previous setTimeout
    // and start a new one
    clearTimeout(window.searchTermTimeout);
    window.searchTermTimeout = setTimeout(() => {
      handleRefresh();
      setSearchTermTemp(event.target.value);
    }, 500);
  };

  const closeModal = () => {
    setSelectedNews(null);
    setIsModalOpen(false);
  };

  const closeModalAndMarkAsRead = () => {
    // get the read news from localStorage
    const readNews = JSON.parse(localStorage.getItem("readNews")) || [];
    // check if the selectedNews._id is already in the readNews array
    if (!readNews.includes(selectedNews._id)) {
      readNews.push(selectedNews._id);
    }
    // save the readNews array to localStorage
    localStorage.setItem("readNews", JSON.stringify(readNews));
    // set the readNews state
    setReadNews(readNews);

    closeModal();
  };

  const handleChangeSource = (event: SelectChangeEvent) => {
    console.log("event.target.value: ", event.target.value);
    setSource(event.target.value);
    setPage(1);
    // save source to localStorage
    localStorage.setItem("source", event.target.value);
    setLoading(true);
  };

  return (
    <div id="home">
      <AppBar position="static">
        <Toolbar>
          <LeftDrawer />
          {screenWidth > 768 && (
            <Typography variant="h6" component="div" onClick={handleRefresh}>
              Tin mới
            </Typography>
          )}
          <div className="search-bar-wrapper">
            <SearchBar>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Search…"
                inputProps={{ "aria-label": "searchTerm" }}
                value={searchTerm}
                onChange={handleSearchTermChange}
              />
            </SearchBar>
          </div>
          <NavBarUserComponent />
        </Toolbar>
      </AppBar>
      {loading && <p>Đang tải...</p>}

      {loading === false && newsData && (
        <div {...swipeHandlers}>
          <NewsTimelineList
            newsData={newsData}
            readNews={readNews}
            onClick={handleCardClick}
            rows={numberOfNews / columns}
            columns={columns}
            pageno={page}
          />
          <div className="hint">
            Chạm lề trái/phải hoặc dùng phím mũi tên trái/phải, page up/down, volume up/down trên bàn phím để chuyển
            trang.
          </div>
        </div>
      )}

      <Stack spacing={{ xs: 1, sm: 2, md: 3, lg: 4 }} direction="row" justifyContent="center">
        <Button variant="contained" color="primary" onClick={handleRefresh} disabled={page === 1}>
          <KeyboardDoubleArrowLeftIcon />
        </Button>
        <Button variant="contained" color="primary" onClick={handlePrevPage} disabled={page === 1}>
          <ArrowBack />
        </Button>
        <Button variant="outlined" onClick={handleNextPage}>
          {page}
        </Button>
        <Button variant="contained" color="primary" onClick={handleNextPage} disabled={page === totalPages}>
          <ArrowForward />
        </Button>
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={source}
            onChange={handleChangeSource}
            style={{ backgroundColor: "white" }}
          >
            <MenuItem value={"all"}>All</MenuItem>
            <MenuItem value={"vnexpress"}>Vnexpress.net</MenuItem>
            <MenuItem value={"cafef"}>Cafef</MenuItem>
            <MenuItem value={"ft"}>ft</MenuItem>
          </Select>
        </FormControl>
      </Stack>
    </div>
  );
};

export default Home;
