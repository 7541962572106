import { useState } from "react";

const useSwipe = (input, config = {}) => {
  const [touchStart, setTouchStart] = useState({ x: 0, y: 0 });
  const [touchEnd, setTouchEnd] = useState({ x: 0, y: 0 });
  const [minSwipeDistance, setMinSwipeDistance] = useState(config.minSwipeDistance ?? 75); // in pixels
  const [touchScreenPart, setTouchScreenPart] = useState(config.touchScreenPart ?? 5); // in pixels
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  const onTouchStart = (e) => {
    setTouchEnd(0); // otherwise the swipe is fired even with usual touch events
    setTouchStart({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY,
    });
  };

  const onTouchMove = (e) =>
    setTouchEnd({
      x: e.targetTouches[0].clientX,
      y: e.targetTouches[0].clientY,
    });

  const onTouchEnd = () => {
    console.log("touchScreenPart", touchScreenPart);
    if (touchScreenPart <= 1) {
      touchScreenPart = 2;
    }
    if (touchStart.x < windowWidth / touchScreenPart) {
      input.onTouchLeft !== undefined && input.onTouchLeft();
    }
    if (touchStart.x > (windowWidth * (touchScreenPart - 1)) / touchScreenPart) {
      input.onTouchRight !== undefined && input.onTouchRight();
    }

    if (!touchStart || !touchEnd) return;
    const distanceX = touchStart.x - touchEnd.x;
    const distanceY = touchStart.y - touchEnd.y;

    const isLeftSwipe = Math.abs(distanceX) > Math.abs(distanceY) && distanceX > minSwipeDistance;
    const isRightSwipe = Math.abs(distanceX) > Math.abs(distanceY) && distanceX < -minSwipeDistance;
    const isUpSwipe = Math.abs(distanceX) < Math.abs(distanceY) && distanceY > minSwipeDistance;
    const isDownSwipe = Math.abs(distanceX) < Math.abs(distanceY) && distanceY < -minSwipeDistance;

    if (isLeftSwipe) {
      input.onSwipedLeft !== undefined && input.onSwipedLeft();
    }
    if (isRightSwipe) {
      input.onSwipedRight !== undefined && input.onSwipedRight();
    }
    if (isUpSwipe) {
      input.onSwipedUp !== undefined && input.onSwipedUp();
    }
    if (isDownSwipe) {
      input.onSwipedDown !== undefined && input.onSwipedDown();
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
  };
};

export default useSwipe;
