// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#home .news-item-clickable {
  cursor: pointer;
  font-weight: 400;
  color: blue;
}

#home ol li {
  padding: 5px 0;
}

#home .search-bar-wrapper {
  flex-grow: 1;
  text-align: center;
}

#home .search-bar-wrapper .MuiInputBase-root {
  width: 100%;
}

.MuiInputLabel-root {
  color: #000000 !important;
}

#home .hint {
  font-size: 12px;
  font-weight: 400;
  color: #000;
  margin-bottom: 10px;
  text-align: left;
  font-style: italic;
  padding: 0 10px;
}
@media (min-width: 768px) {
  #home .hint {
    text-align: center;
  }
}
`, "",{"version":3,"sources":["webpack://./src/views/Home.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,WAAW;EACX,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE;IACE,kBAAkB;EACpB;AACF","sourcesContent":["#home .news-item-clickable {\n  cursor: pointer;\n  font-weight: 400;\n  color: blue;\n}\n\n#home ol li {\n  padding: 5px 0;\n}\n\n#home .search-bar-wrapper {\n  flex-grow: 1;\n  text-align: center;\n}\n\n#home .search-bar-wrapper .MuiInputBase-root {\n  width: 100%;\n}\n\n.MuiInputLabel-root {\n  color: #000000 !important;\n}\n\n#home .hint {\n  font-size: 12px;\n  font-weight: 400;\n  color: #000;\n  margin-bottom: 10px;\n  text-align: left;\n  font-style: italic;\n  padding: 0 10px;\n}\n@media (min-width: 768px) {\n  #home .hint {\n    text-align: center;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
