import create from "zustand";

export const useStore = create((set) => ({
  // get the data from local storage
  authData:
    localStorage.getItem("authData") &&
    localStorage.getItem("authData") !== undefined &&
    localStorage.getItem("authData") !== "undefined"
      ? JSON.parse(localStorage.getItem("authData"))
      : null,

  setAuthData: (authData) => {
    localStorage.setItem("authData", JSON.stringify(authData));
    set({ authData });
  },
}));
