// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.pdf-viewer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.pdf-viewer nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.pdf-viewer button {
  background-color: #f5f5f5;
  border: none;
  color: #000;
  padding: 1rem;
  list-style: none;
  font-size: 1.3rem;
  margin: 0.7rem;
  border-radius: 20%;
  cursor: pointer;
}

.pdf-viewer .previous {
  background-color: #f1f1f1;
  color: black;
}

.pdf-viewer .next {
  background-color: #4a8fed;
  color: white;
}

.pdf-viewer p {
  font-size: 1.3rem;
  background-color: #f5f5f5;
  padding: 1rem;
}

.pdf-viewer .react-pdf__Page__canvas {
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/views/PdfViewer.css"],"names":[],"mappings":"AACA;EACE,SAAS;EACT;8CAC4C;EAC5C,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,+EAA+E;AACjF;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;EACnB,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,8BAA8B;AAChC;;AAEA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;EACX,aAAa;EACb,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,iBAAiB;EACjB,yBAAyB;EACzB,aAAa;AACf;;AAEA;EACE,YAAY;AACd","sourcesContent":["@import url(\"https://fonts.googleapis.com/css2?family=Roboto&display=swap\");\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\",\n    \"Droid Sans\", \"Helvetica Neue\", sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\", monospace;\n}\n\n.pdf-viewer {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  align-items: center;\n  margin: 0 auto;\n}\n\n.pdf-viewer nav {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.pdf-viewer button {\n  background-color: #f5f5f5;\n  border: none;\n  color: #000;\n  padding: 1rem;\n  list-style: none;\n  font-size: 1.3rem;\n  margin: 0.7rem;\n  border-radius: 20%;\n  cursor: pointer;\n}\n\n.pdf-viewer .previous {\n  background-color: #f1f1f1;\n  color: black;\n}\n\n.pdf-viewer .next {\n  background-color: #4a8fed;\n  color: white;\n}\n\n.pdf-viewer p {\n  font-size: 1.3rem;\n  background-color: #f5f5f5;\n  padding: 1rem;\n}\n\n.pdf-viewer .react-pdf__Page__canvas {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
