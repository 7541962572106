import { GoogleOAuthProvider, GoogleLogin, googleLogout } from "@react-oauth/google";
import { googleLogin } from "../apis/users.api";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import { useStore } from "../hooks/useStore";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import AdbIcon from "@mui/icons-material/Adb";
import { useState } from "react";
import { redirect } from "react-router-dom";
import Badge from "@mui/material/Badge";
import StarIcon from "@mui/icons-material/Star";

const settings = ["Profile", "Account", "Logout"];

const NavBarUserComponent = () => {
  const setAuthData = useStore((state) => state.setAuthData);
  const currentUser = useStore((state) => state.authData);

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleLogout = async () => {
    googleLogout();
    localStorage.removeItem("AuthData");
    setAuthData(null);
    // navigate to home page
    redirect("/");
  };

  // not logged in
  if (!currentUser) {
    return (
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleLogin
          onSuccess={async (credentialResponse) => {
            console.log(credentialResponse);
            const { data } = await googleLogin({ token: credentialResponse.credential });
            localStorage.setItem("authData", JSON.stringify(data));
            setAuthData(data);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </GoogleOAuthProvider>
    );
  }

  // logged in
  return (
    // show current user avatar and name
    <Box sx={{ flexGrow: 0 }}>
      <Tooltip title="Open settings">
        <Badge
          badgeContent={<StarIcon fontSize="10px" />}
          color="error"
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={currentUser.fullname} src={currentUser.avatar} />
          </IconButton>
        </Badge>
      </Tooltip>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        <MenuItem key="logout" onClick={handleLogout}>
          <Typography textAlign="center">Logout</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default NavBarUserComponent;
