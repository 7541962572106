import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./views/Home";
import NewsDetail from "./views/NewsDetail";
import NewsDetailWeb from "./views/NewsDetailWeb";
import Signup from "./views/Signup";
import PdfViewer from "./views/PdfViewer";
import MentalMath from "./views/Math/MentalMath";

const App = () => {
  return (
    <Router>
      <div>
        {/* <Navbar /> */}
        <Routes>
          <Route path="/" exact element={<Home />} />
          <Route path="/:pageno" exact element={<Home />} />
          <Route path="/:pageno" exact element={<Home />} />
          <Route path="/news/:id/:page" exact element={<NewsDetailWeb />} />
          <Route path="/news/detail/:id" element={<NewsDetail />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/pdf/:page" element={<PdfViewer />} />
          <Route path="/math/mental" element={<MentalMath />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
